export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    action: 'read',
    resource: 'Sop',
  },
  {
    title: 'Data Quality',
    route: 'dataquality',
    icon: 'CheckCircleIcon',
    children: [
      {
        title: 'Product Master',
        route: 'product-master',
        action: 'read',
        resource: 'Admin',
      },
    ],
  },
  {
    title: 'Visuals',
    route: 'visual',
    icon: 'PieChartIcon',
    children: [
      {
        title: 'Database',
        route: 'visual-database',
        action: 'read',
        resource: 'Sop',
      },
      {
        title: 'Prepare Summary',
        route: 'prepare-summary',
        action: 'read',
        resource: 'Sop',
      },
      // {
      //   title: 'SOP Simulation',
      //   route: 'sop-simulation',
      //   action: 'read',
      //   resource: 'Sop',
      // },
      // {
      //   title: 'Cobertura',
      //   route: 'cobertura',
      //   action: 'read',
      //   resource: 'Sop',
      // },
      // {
      //   title: 'Executive',
      //   route: 'executive',
      //   action: 'read',
      //   resource: 'Sop',
      // },
      // {
      //   title: 'Executive',
      //   route: 'executive1',
      //   action: 'read',
      //   resource: 'Sop',
      // },
    ],
  },
  {
    title: 'S&OP Simulation',
    route: 'simulator ',
    icon: 'ActivityIcon',
    children: [
      // {
      //   title: 'Simulador',
      //   route: 'simulador',
      //   action: 'read',
      //   resource: 'Sop',
      // },
      {
        title: 'Simulador',
        route: 'simulador2',
        action: 'read',
        resource: 'Sop',
      },
      // {
      //   title: 'Propuesta 2  ',
      //   route: 'simulador3',
      //   action: 'read',
      //   resource: 'Sop',
      // },
      {
        title: 'Post-Simulación',
        route: 'tabla-post-simulacion',
        action: 'read',
        resource: 'Sop',
      },
    ],
  },
  {
    title: 'Executive',
    route: 'executive',
    icon: 'CoffeeIcon',
    children: [
      {
        title: 'Risk & Opps',
        route: 'executive1',
        action: 'read',
        resource: 'Sop',
      },
    ],
  },
  {
    title: 'Users',
    route: 'users',
    icon: 'UserIcon',
    action: 'read',
    resource: 'Admin',
  },
  // descomentar para aparecer SETTINGS SOP
  // {
  //   title: 'Settings Sop',
  //   route: 'settings-sop',
  //   icon: 'SettingsIcon',
  //   action: 'read',
  //   resource: 'Admin',
  // },
]
